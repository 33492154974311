import React from "react"

import SEO from "../components/seo"
import { useMatchMedia } from "../utils/helpers"

const IndexPage = () => {
  const isLaptop = useMatchMedia("laptop")

  return (
    <div className="container mx-auto">
      <SEO title="Home" />

      <div className="pt-24">
        <h1>h1 Text.</h1>
        <h2>h2 Lorem ipsum dolor sit.</h2>
        <h3>h3 Lorem ipsum dolor sit.</h3>
        <h4>h4 Lorem ipsum dolor sit.</h4>
        <h5>h5 Lorem ipsum dolor sit.</h5>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore,
          blanditiis sed aspernatur tenetur in modi ab a sit, asperiores
          dignissimos vero voluptatibus quasi aliquam itaque aliquid eligendi
          temporibus consectetur beatae eius quaerat consequatur ex dolorem
          laborum minima! Ab, saepe accusamus!
        </p>
      </div>

      <div className="mt-24">
        <h5>util/helpers.js</h5>
        <p>
          useMatchMedia isLaptop:{" "}
          <span className="font-bold"> {isLaptop ? "true" : "false"} </span>
        </p>
      </div>
    </div>
  )
}

export default IndexPage
